import React, { Component } from 'react'
import { getLanguageFromPath } from 'utils/routing'
import LayoutWrapper from 'src/layouts/LayoutWrapper'

const banner = require(`content/banner.png`)

import Hero from 'components/Hero/Hero'

import './Search.scss'

class Search extends Component {
	isMobile = false


	render() {
        const {
            location
        } = this.props

		const currentLanguage = location ? getLanguageFromPath(location.pathname) : 'en'

		if (typeof window != 'undefined') {
			this.isMobile = window.outerWidth <= 500 ? true : false
		}

		return (
			<LayoutWrapper>
				<div className="search-container">
					<Hero
						backgroundImage={banner}
						title={currentLanguage === 'ar' ? 'ابحث في موقع رحمة' : 'Search Rahma'}
						isSearch={true}
                        location={location}
					/>
				</div>
			</LayoutWrapper>
		);
	}
};

export default Search;
